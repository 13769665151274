import anime from "animejs";

const mainApiUrl = process.env.MAIN_API_URL;
const fungusApiEndpoint = `${mainApiUrl}/api`;

const queryStringForEvent = window.location.search;
const urlParamsForEvent = new URLSearchParams(queryStringForEvent);

const eventId = urlParamsForEvent.get('eventId') || 1;

let strapi = {
    offsetWriterToken: process.env.FUNGUS_API_KEY,
    eventWriteEndpoint: `${fungusApiEndpoint}`,

    assetsUrl: mainApiUrl,
    apiEndpoint: fungusApiEndpoint,
    liveDataEndpoint: `${fungusApiEndpoint}/events/${eventId}/live`,
    adsEndpoint: `${fungusApiEndpoint}/events/${eventId}/ads`,
    shoutoutEndpoint: `${fungusApiEndpoint}/events/${eventId}/shoutout`,
    liveEventData: [],
    gufDonationData: [],
    oldDonatorLeaderboard: [],
    incentives: [],

    toCallWhenReady: [],
    toCallWhenUpdated: [],
    firstInit: true,

    currentBgmIndex: 0,

    currentBgmScrollIndex: 0,
    maxBgmScrollIndex: 2,
    bgmScrollMaxLeft: 0,
    bgmScrollLeft: 0,
    bgmScrollElements: [],
    bgmCurrentTitle: '',
    scrollBgmInterval: null,

    shouldSendAds: false,
    adSendingTimeout: null,

    scrollBgmLoop: () => {
        strapi.bgmScrollElements = document.querySelectorAll('#media-player .media-title-text');
        strapi.bgmScrollMaxLeft = strapi.bgmScrollElements[strapi.currentBgmScrollIndex].clientWidth;
        strapi.bgmScrollElements.forEach((element, i) => {
            element.style.order = i;
        });
        strapi.scrollBgmInterval = setInterval(function () {
            strapi.bgmScrollElements.forEach((element) => {
                element.style.left = strapi.bgmScrollLeft + 'px';
            });

            strapi.bgmScrollLeft -= 1;
            if (strapi.bgmScrollLeft <= strapi.bgmScrollMaxLeft * -1) {
                strapi.bgmScrollLeft = 0;
                strapi.currentBgmScrollIndex++;
                if (strapi.currentBgmScrollIndex > strapi.maxBgmScrollIndex)
                    strapi.currentBgmScrollIndex = 0;

                strapi.bgmScrollElements.forEach((element) => {
                    let order = parseInt(element.style.order);
                    order--;
                    if (order < 0)
                        order += strapi.bgmScrollElements.length;

                    element.style.order = order;
                });
                strapi.bgmScrollMaxLeft = strapi.bgmScrollElements[strapi.currentBgmScrollIndex].clientWidth;
            }
        }, 30);
    },

    fetchGufInfo: async () => {
        try {
            fetch(strapi.liveDataEndpoint, {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                },
            }).then(async (response) => {
                const responseCode = response.status;
                if (responseCode !== 200) {
                    console.error("Failed to fetch event info:", response);
                    return;
                }

                const responseJson = await response.json();
                strapi.liveEventData = responseJson.data;
                if (strapi.firstInit) {
                    strapi.toCallWhenReady.forEach(toCall => { toCall(); });
                    strapi.firstInit = false;
                }
                strapi.toCallWhenUpdated.forEach(toCall_1 => { toCall_1(); });
            });
        } catch (error) {
            console.error(error);
        }
    },

    getMarathonDate: () => {
        return strapi.liveEventData?.attributes?.runDate;
    },

    getTotalDonationOffset: () => {
        return strapi.liveEventData?.attributes?.donationOffset ?? 0;
    },

    getDonationData: () => {
        return strapi.liveEventData?.donations ?? [];
    },

    getTotalDonation: () => {
        return strapi.getDonationData()?.total ?? 0;
    },

    getTotalDonationsWithOffset: () => {
        const totalDonation = strapi.getTotalDonation() + strapi.getTotalDonationOffset();
        if (totalDonation <= 0) return 0;

        return totalDonation;
    },

    getDonatorsLeaderboard: () => {
        let donatorLeaderboard = strapi.getDonationData()?.top_donors?.map((donation, i) => {
            const oldData = strapi.oldDonatorLeaderboard[i];
            const newData = strapi.getDonatorsLeaderboardUpdateCheckKey(donation);

            let donationFiltered = {
                name: donation.name,
                total: donation.amount_sum,
                updated: oldData !== newData,
            };

            strapi.oldDonatorLeaderboard[i] = newData;
            return donationFiltered;
        }) ?? [];


        return donatorLeaderboard;
    },

    getDonationLeaderboard: () => {
        let donationLeaderboard = strapi.getDonationData()?.top_donations?.map(donation => {
            let filteredDonations = {
                name: donation.name ?? 'Anonimo',
                total: donation.amount,
            };

            return filteredDonations;
        }) ?? [];


        return donationLeaderboard;
    },

    getDonatorsLeaderboardUpdateCheckKey: (donationAttributes) => {
        if (donationAttributes === undefined) return '';

        return donationAttributes.name + '-' + donationAttributes.total;
    },

    getInforollRows: () => {
        return strapi.liveEventData?.live?.inforoll_text;
    },

    getFreezeInforoll: () => {
        return strapi.liveEventData?.live?.freeze_inforoll ?? false;
    },

    getFreezeInforollMedia: () => {
        return strapi.liveEventData?.attributes?.freezeMedia?.data ?? [];
    },

    getFrozenInforollText: () => {
        return strapi.liveEventData?.live?.frozen_inforoll_text ?? '';
    },

    getOffsetHours: () => {
        return -strapi.liveEventData?.live?.offset_hours ?? 0;
    },

    getOffsetMinutes: () => {
        return -strapi.liveEventData?.live?.offset_minutes ?? 0;
    },

    getDonationReason: () => {
        return strapi.liveEventData?.live?.donation_recipient ?? '';
    },

    getCurrentRun: () => {
        return strapi.liveEventData?.current_timeslot ?? [];
    },


    getRunner: (ord) => {
        return oengus.getPlayingNow().submission?.runners.filter(r => r.order === ord - 1)[0] ?? null;
    },

    getRunnerName: (ord) => {
        return strapi.getRunner(ord)?.user?.name ?? '';
    },

    getRunnerSocials: (ord) => {
        return strapi.getRunner(ord)?.user?.socials ?? [];
    },

    getRunnerSocialsData: (ord) => {
        return strapi.getRunnerSocials(ord)
            .filter(social => social.type === 'twitch' || social.type === 'youtube')
            .map(function (social) {
                const handle = social?.handle ?? '';
                const type = social?.type ?? '';
                let icon = '';
                if (type === 'twitch') {
                    icon = '/uploads/icon_twitch_f4e80a88e8.png';
                } else if (type === 'youtube') {
                    icon = '/uploads/icon_youtube_bb32763b9e.png';
                }

                if (icon !== '') {
                    icon = strapi.assetsUrl + icon;
                }

                return { handle: handle, icon: icon };
            }) ?? [];
    },

    getHostName: () => {
        return oengus?.playingNow?.reader ?? '';
    },

    shouldShowLeaderboard: () => {
        return strapi.liveEventData?.live?.show_leaderboard ?? true;
    },

    getDonationGoalAmount: () => {
        return strapi.liveEventData?.live?.goal_amount ?? 0;
    },

    getDonationGoalFlair: () => {
        return strapi.liveEventData?.live?.goal_flair ?? '';
    },

    getDonationDeploySurprise: () => {
        return true;
    },

    getBreakScreenAds: () => {
        return (strapi.liveEventData?.attributes?.ads?.data ?? []).map(ad => ad.attributes?.url ?? '');
    },

    getCouch: () => {
        return oengus?.playingNow?.couch ?? '';
    },

    getBreakScreenHost: () => {
        return strapi.getHostName();
    },

    parseIncentives: () => {
        strapi.incentives = [];
        strapi.getDonationData()?.fixedIncentives?.map(rawIncentive => {
            let incentive = [];
            incentive.type = 'fixed';
            incentive.name = rawIncentive.name;
            incentive.total = rawIncentive.total;
            incentive.goal = rawIncentive.goal;
            incentive.gameName = rawIncentive.gameName;
            incentive.category = rawIncentive.category;
            strapi.incentives.push(incentive);
        })

        const optionIncentives = strapi.getDonationData()?.optionIncentives;
        let parentIds = strapi.getDonationData()?.optionIncentives?.map(i => i.parentId) ?? [];
        let unique = [];
        parentIds.map(i => unique[i] = i);
        unique = unique.filter(Number);
        unique.map(parentId => {
            let childrenIncentive = optionIncentives.filter(o => o.parentId == parentId);
            let firstIncentive = childrenIncentive[0];
            let incentive = [];
            incentive.type = 'option';
            incentive.name = firstIncentive.parentName;
            incentive.total = firstIncentive.goal;
            incentive.gameName = firstIncentive.gameName;
            incentive.category = firstIncentive.category;
            incentive.options = []
            childrenIncentive.map(childIncentive => {
                if (childIncentive.id == null) return;

                let innerIncentive = [];
                innerIncentive.id = childIncentive.id;
                const regex = /^(.*?) -.*$/ig;
                innerIncentive.name = childIncentive.name.replaceAll(regex, '$1');
                innerIncentive.total = Number(childIncentive.total);
                incentive.options.push(innerIncentive);
            })
            incentive.options.sort((a, b) => b.total - a.total);
            strapi.incentives.push(incentive);
        })
    },

    setOffset: (hours, minutes, date, timeslotId) => {
        const data = {
            offset_hours: hours,
            offset_minutes: minutes,
            run_date: date,
            timeslot_id: timeslotId,
        };
        console.log("Setting offset with data:", data);
        try {
            const response = fetch(strapi.liveDataEndpoint, {
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${strapi.offsetWriterToken}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                },
                body: JSON.stringify(data),
            }).then((data) => {
                const responseCode = data.status;
                if (responseCode !== 200) {
                    console.error("Failed to set offset:", data);
                    return;
                }

                console.log(data);
                window.location.reload();
            });
        } catch (error) {
            console.error(error);
        }
    },

    sendAds: () => {
        const data = {
            data: {
                sendAds: true,
            }
        };
        const response = fetch(strapi.adsEndpoint, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${strapi.offsetWriterToken}`,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(data),
        }).then((data) => {
            console.log("Sent ads and got back:", data);
        });
    },

    sendShoutout: () => {
        // TODO: get the twitch social for the current run runner and send a shoutout

        const data = {
            data: {
                sendShoutout: true,
            }
        };
        const response = fetch(strapi.shoutoutEndpoint, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${strapi.offsetWriterToken}`,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(data),
        }).then((data) => {
            console.log("Sent shoutout and got back:", data);
        });
    },

    getNextRunBgms: () => {
        return [];
        // TODO: implement
    },

    getNextRunBgm: () => {
        let bgms = [];
        if (strapi.getFreezeInforoll())
            bgms = strapi.getFreezeInforollMedia();
        else
            bgms = strapi.getNextRunBgms();

        if (bgms.length == 0)
            return [];

        if (bgms.length <= strapi.currentBgmIndex)
            strapi.currentBgmIndex = 0;

        return bgms[strapi.currentBgmIndex].attributes ?? [];
    },

    playNextRunBgmWithDelay: () => {
        setTimeout(strapi.playNextRunBgm, 3000);
    },

    setBgmMediaTitles: (nextRunBgm) => {
        const titleArray = [
            nextRunBgm?.caption || '',
            nextRunBgm?.alternativeText || '',
        ];
        strapi.bgmCurrentTitle = titleArray.join(' - ');
        anime({
            targets: '#media-player .media-title-container',
            opacity: 0,
            duration: 2000,
            easing: 'linear',
            complete: () => {
                strapi.bgmScrollLeft = 0;
                clearInterval(strapi.scrollBgmInterval);
                document.querySelectorAll('#media-player .media-title-text')
                    .forEach(element => element.innerText = strapi.bgmCurrentTitle);
                strapi.scrollBgmLoop();
                anime({
                    targets: '#media-player .media-title-container',
                    opacity: 1,
                    duration: 2000,
                    easing: 'linear',
                });
            }
        });
    },

    playNextRunBgm: () => {
        let nextRunBgm = strapi.getNextRunBgm();
        if (!nextRunBgm || nextRunBgm.length == 0)
            return;

        console.log("Next run BGM not empty, playing", nextRunBgm);
        strapi.playBgm(nextRunBgm);

        strapi.currentBgmIndex++;
    },

    playBgm: (bgm) => {
        strapi.setBgmMediaTitles(bgm);
        let sourceSelector = document.querySelector("#media-player audio source");
        const bgmType = bgm.mime;
        const bgmUrl = strapi.assetsUrl + bgm.url;
        sourceSelector.type = bgmType;
        sourceSelector.src = bgmUrl;
        console.log("BGM", bgmType, bgmUrl);
        let audio = document.querySelector("#media-player audio");
        audio.load();
        audio.volume = 0;
        anime({
            targets: audio,
            duration: 500,
            easing: 'linear',
            volume: 0.2,
        });
        audio.play();
    },

    callWhenReady: (fn) => {
        strapi.toCallWhenReady.push(fn);
    },

    callWhenUpdated: (fn) => {
        strapi.toCallWhenUpdated.push(fn);
    },
}

export { strapi };
window.strapi = strapi;
window.playBgm = false;
window.cake = false;
strapi.callWhenReady(function () {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);


    window.cake = urlParams.get('cake') === 'true' || false;
    if (window.cake)
        console.log("The cake is a lie.");

    strapi.shouldSendAds = urlParams.get('sendAds') === 'true' || false;
    if (strapi.shouldSendAds) {
        strapi.adSendingTimeout = setTimeout(strapi.sendAds, 10000);
        console.log("Sending ads in 10 seconds.");
    }

    const shouldSendShoutout = urlParams.get('sendShoutout') === 'true' || false;
    if (shouldSendShoutout) {
        setTimeout(function () {
            strapi.sendShoutout();
            console.log("Sending shoutout.");
        }, 1000 * 60 * 2);
    }

    const bgm = urlParams.get('bgm') === 'true' || false;
    if (!bgm)
        return;

    window.playBgm = true;

    console.log("BGM enabled, setting up event listeners and playing first BGM...");
    const audio = document.querySelector("#media-player audio");
    audio.addEventListener("ended", strapi.playNextRunBgmWithDelay, false);
    audio.volume = 0.2;
    strapi.playNextRunBgmWithDelay();
});

strapi.callWhenReady(function () {
    strapi.scrollBgmLoop();
});